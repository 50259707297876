<div class="g-navbar" fxLayout="row" fxLayoutAlign="center center" >
  <div fxFlex="60" fxLayout="row" fxLayoutAlign="end center" class="navbar-container">
    <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center" class="navbar-container">
      <a class="img-sunshine-logo" href="{{redirect}}">
        <img width="300" src="./assets/images/sunshine-logo.svg" alt="">
      </a>
    </div>

  </div>
  <div *ngIf="userData" fxFlex="40" fxFlex.md="50" fxLayout="row" fxLayoutAlign="start center" class="user-header-side" [ngClass]="{'background-green': (userData.type == 'Read-Only') || (userData.type == 'Caslls')}">
    <div fxFlex="10" fxFlex.md="20" fxLayout="column" fxLayoutAlign="center center">
      <img *ngIf="userData.img" class="avatar" [src]="userData.img" alt="">
      <img *ngIf="!userData.img" class="avatar" src="https://www.ecaslls.com/platform/assets/images/default-profile-icon.png" alt="">
    </div>
    <div  fxFlex="30" fxFlex.md="40" fxLayout="column" fxLayoutAlign="center start">
      <h4 class="g-text-white username">{{userData.first_name}} {{userData.last_name}}</h4>
      <h5 class="g-text-light-gray user-role" *ngIf="userRole != 'Read-Only' && userRole != 'Caslls'">{{userData.position}}</h5>
      <h5 class="g-text-white user-role" *ngIf="userRole == 'Read-Only' || userRole == 'Teacher'">{{userRole == 'Read-Only' ? 'READ-ONLY': 'CASLLS'}} USER</h5>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxFlex="30" fxFlex.md="40">
      <mat-icon *ngIf="userData.type != 'Owner'" fontSet="fas" fontIcon="fa-pencil-alt" (click)="editUser()"></mat-icon>
      <mat-icon fontSet="fas" fontIcon="fa-sign-out-alt" (click)="logout()"></mat-icon>
      <mat-icon fontSet="fas" [matMenuTriggerFor]="menu" fontIcon="fa-question"></mat-icon>
      <mat-menu #menu="matMenu" class="custom-menu-header" xPosition="after" yPosition="below">
        <a mat-menu-item [href]="" (click)="setHelpOn()">
          <mat-icon fontSet="fas"  fontIcon="fa-question"></mat-icon><span>HELP</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-menu-item href="https://www.ecaslls.com/platform/pdf/Sunshine_User_Roles.pdf" target="_blank">
          <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon><span>USER ROLES</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-menu-item href="https://www.ecaslls.com/platform/pdf/Sunshine_Guide.pdf" target="_blank">
          <mat-icon fontSet="fas" fontIcon="fa-book">
          </mat-icon><span>USER GUIDES</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-menu-item href="https://www.ecaslls.com/platform/pdf/How_to_Mark_and_Not_Mark_eCASLLS.pdf" target="_blank">
          <mat-icon fontSet="fas" fontIcon="fa-book"></mat-icon><span>MARKING ECASLLS</span>
        </a>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="!userData" fxFlex="40" fxFlex.md="50" fxLayout="row" fxLayoutAlign="start center"></div>
</div>

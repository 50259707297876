import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from  '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralApiService } from 'src/app/shared/services/general-api.service';
import { AlertService } from '../../alert/alert.service'
import { AppConfig } from 'src/app/app.config'; 
@Component({
  selector: 'login-forgot',
  templateUrl: './login-forgot.component.html',
  styleUrls: ['./login-forgot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginForgotComponent implements OnInit {

  public buttonError: boolean = false
  public buttonLog: string = 'Loading'
  public email:any = ''
  public isLoading: boolean = false
  public recoverForm: FormGroup
  public shakeIt: boolean = false
  public isDisabled: boolean = false
  public staticAlertClosed: boolean = false
  public url:string = AppConfig.settings.hosts.web;

  constructor(
    public _route: Router,
    public _activateRouter: ActivatedRoute,
    private _generalApiService: GeneralApiService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.buildForm()
  }

  public buildForm(){
    const emailPattern = '^^[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    this.recoverForm = new FormGroup({ 
      email: new FormControl ('', Validators.compose([Validators.required, Validators.email])),
      url:new FormControl (this.url + 'reset-password'),
    })
  }

  public shakeDialog() {
    this.shakeIt = true;
    setTimeout((arg) => {
      this.shakeIt = false;
    },
    800);
  }
  
  public errorMessage(){
    this.buttonLog = 'Unable to reset password';
    this.buttonError = true;
    setTimeout(arg => {
      this.isLoading = false;
      this.buttonLog = 'Loading';
      this.buttonError = false
     },
     3000); 
  }

  public isEmpty(){
    
    if(this.recoverForm.get('email').hasError('required') && this.recoverForm.get('email').hasError('email')) {
      this.isDisabled = true
    } else {
      this.isDisabled = false
    }
  }

  public sendEmail(){
    if(this.recoverForm.controls['email'].hasError('email')){
      this.alertService.warning('Insert a valid Email format')
    } else {
      this.isLoading = true
      this._generalApiService.sendRecoverEmail(this.recoverForm.value).subscribe(res => {
        this.alertService.success('The recovery email has been sent, please check your inbox')
        this._route.navigate(['/login'])
      }, error => {
        this.alertService.warning('error')
        this.shakeDialog()
        this.errorMessage()
      })
    }
  }
  
}

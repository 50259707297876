<div fxLayout="column">
    <div class="container-login" fxLayout="row" fxLayoutAlign="center">
        <div [ngClass]="{'shake': shakeIt, 'card': true}">
            <h4 class="card-header" fxLayoutAlign="center"><img width="300" src="{{url}}assets/images/sunshine-logo.svg" alt="">
            </h4>
            <div class="card-body">
                <form name="loginForm" [formGroup]="authForm" (ngSubmit)="login()">
                    <div class="form-group">
                        <input type="email" class="form-control form-control-lg" placeholder="Email"
                            formControlName="email">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control form-control-lg" placeholder="Password"
                            formControlName="password">
                    </div>
                    <button type="submit" class="btn sign-btn"
                        [ngClass]="{'red' : buttonError, 'green': !buttonError}">{{isLoading ? buttonLog: 'Sign in'
                        }}</button>
                </form>
                <div class="forgot-link" fxLayoutAlign="center center">
                    <a href="" [routerLink]="['/login-forgot']">Forgot Password?</a>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="py-5 updateAlt">
        <div fxFlex="78">
            <div fxLayout="column" fxLayoutAlign="center start" class="bannerAlt rounded bg-light p-4">
                <div style="text-decoration: underline;color:#28a745;font-size: larger;font-weight: bold;padding-top: 20px;width: 100%;"
                    fxLayout="row" fxLayoutAlign="center">
                    ATTENTION:
                </div>
                <div fxLayout="column" fxLayoutAlign="center start">
                    <div>
                        <p style="padding-top: 10px;">Dear eCASLLS Users,</p>
                    </div>

                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        As many of you enter the back-to-school season, we wanted to inform you that we underwent a platform-wide systems update over the summer break in an effort to continue improving the eCASLLS experience for all.
                    </p>
                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        While not required, it is <u style="margin-right: 4px;"><strong>strongly</strong></u> <strong>recommended</strong> that you perform one of the following actions prior to logging into your account to prevent any browser data from before the update to interfere with your experience.
                    </p>
                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        The guides below will outline how to properly clear your browser data which will help ensure that your browser loads the latest version of the eCASLLS platform. There are three ways to do this, all of which serve a different purpose.
                    </p>
                    <p style="padding-top: 10px;">
                        <a href="https://www.ecaslls.com/platform/pdf/Sunshine_eCASLLS_Full_Cache_Clear.pdf" target="_blank"><span style="font-weight:600;">1. Full Clear of All Browsing Data (Recommended).</span> </a>
                    </p>
                    <p style="padding-top: 10px;">
                        <a href="https://www.ecaslls.com/platform/pdf/Sunshine_eCASLLS_Partial_Cache_Clear.pdf" target="_blank"><span style="font-weight:600;">2. Partially Clear All Browsing Data.</span></a>
                    </p>
                    <p style="padding-top: 10px;">
                        <a href="https://www.ecaslls.com/platform/pdf/Sunshine_eCASLLS_Site-Only_Cache_Clear.pdf" target="_blank"><span style="font-weight:600;">3. Clear Site-Specific Browsing Data.</span></a>
                    </p>
                    <p style="font-weight: normal; margin: 0;padding-top: 10px;">
                        We understand the potential frustration in this additional step in your back-to-school activities and appreciate your patience and cooperation as we strive to better eCASLLS for all. If you have any questions or lingering issues, please contact our team at <a href="mailto:edproducts@sunshinecottage.org">edproducts@sunshinecottage.org</a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>